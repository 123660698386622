import React from 'react';
import { NavProgressBar } from '../../../nextgen/plushcare-components';
import { useStepper } from '../StepperProvider';

export const StepperContextProgressBar = () => {
  const { selectors: { getTotal, getCurrent } } = useStepper();

  return (
    <StepperProgressBar
      total={getTotal()}
      progress={getCurrent()}
    />
  );
};
export const StepperProgressBar = NavProgressBar;
