import { styled } from "styled-components";


export const NavigationSubItem = styled.div`
  padding: 0 10px;
  display: flex;
  column-gap: 8px;
  align-items: center;
  color: rgb(102, 102, 102);

  font-family: "DM Sans", sans-serif;
  font-size: 16;
  font-weight: 400;
  line-height: 1.5;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    color: rgb(39, 158, 146);
    text-decoration: underline;
  }
`;
