import React, { ReactNode } from 'react';
import Navigation from 'src/js/nextgen/components/navigation/Navigation';
import { Navigation as AccoladeXNavigation } from 'src/js/nextgen/accolade-x/components/Navigation';
import { useAppVariant } from 'utils/hooks/useAppVariant';

export default function WithNavigation({ children }: { children: ReactNode }) {
  const { isAccoladeX } = useAppVariant();

  return (
    <div>
      {
        isAccoladeX
          ? <AccoladeXNavigation />
          : <Navigation />
      }
      {children}
    </div>
  );
}
