import styled from '@emotion/styled';
import { colors } from '../../../nextgen/plushcare-components';

export const StepperHeaderStandardLayout = styled.section`
  margin: -5px auto 5px;
`;

export const StepperHeaderStickyLayout = styled(StepperHeaderStandardLayout)`
  background: ${({ scrolled }) => (scrolled ? `${colors.white}` : 'none')};
  position: sticky;
  z-index: 3;
  top: 0;
`;
