import React, { ReactNode, PropsWithChildren } from 'react';
import styled from '@emotion/styled';
import { Columns } from 'react-bulma-components';

const { Column } = Columns;

type Props = {
  wideView?: boolean,
  children: ReactNode,
}

// Main wrapper for all page-components of any type of Booking flow.
// Sets margins, paddings and max-width depending on viewport
export function BookingFlowMasterWrapper({ children, wideView = false }: PropsWithChildren<{ wideView?: boolean}>) {
  return (
    <Wrapper className="container-fluid" wideView>
      <Columns centered>
        <Column
          tablet={{ size: wideView ? 12 : 8 }}
          desktop={{ size: wideView ? 12 : 6 }}
          fullhd={{ size: wideView ? 8 : 5 }}
        >
          {children}
        </Column>
      </Columns>
    </Wrapper>
  );
}

const Wrapper = styled.div<Props>`
  padding: ${props => (props.wideView ? '0.75rem 20px 0 20px' : '0.75rem 0 0 0')};
`;
