import React, { useEffect } from 'react';
import { Column, Columns } from '../../../nextgen/layout/columns';

import { StepperHeaderStandardLayout, StepperHeaderStickyLayout } from './StepperHeader.styled';

const HeaderContent = ({ indicatorComponent, children }) => (
  <Columns isDevice="mobile" isGapless isVcentered>
    <Column isSize="1/3">
      {indicatorComponent}
    </Column>
    <Column>
      {children}
    </Column>
  </Columns>
);

const Sticky = ({ children }) => {
  const [scrolled, setScrolled] = React.useState(false);

  const handleScroll = () => {
    const offset = window.scrollY;
    if (offset > 50) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  });

  return (
    <StepperHeaderStickyLayout scrolled={scrolled}>
      {children}
    </StepperHeaderStickyLayout>
  );
};

const Standard = ({ children }) => <StepperHeaderStandardLayout>{children}</StepperHeaderStandardLayout>;

export const StepperHeader = (props) => {
  const { sticky = false, progressBarComponent = null } = props;
  const Wrapper = sticky ? Sticky : Standard;
  return (
    <>
      {progressBarComponent}
      <Wrapper>
        <HeaderContent {...props} />
      </Wrapper>
    </>
  );
};
