import React from 'react';
import dynamic from 'next/dynamic';
import styled from 'styled-components';
import { Container } from 'react-bulma-components';
import Image from 'next/image';
import {
  AccoladeOutlinedIcon,
  MessageOutlinedIcon,
  SpendingOutlinedIcon,
  StethoscopeIcon,
  BellIcon,
  SettingsIcon,
  HeartbeatIcon,
} from '@accolade/design-system-react-icons';
import { AccoladeLogo } from '@accolade/design-system-react';
import { useBrand } from 'src/js/nextgen/providers/BrandProvider';
import { NavigationLink } from './NavigationLink';
import { NavigationSubItem } from './NavigationSubItem';
import { NavigationItem } from './NavigationItem';
import CelesteLogo from 'public/img/nextgen/Celeste.png';

const Desktop = dynamic(
  () => import('@accolade-x/atoms').then((mod) => mod.Desktop),
  { ssr: false },
);

const TabletAndBelow = dynamic(
  () => import('@accolade-x/atoms').then((mod) => mod.TabletAndBelow),
  { ssr: false },
);

export const desktopItems = [
  {
    href: '/messaging',
    title: 'Messages',
    icon: <MessageOutlinedIcon />,
  },
  {
    href: '/benefits',
    title: 'Benefits',
    icon: <SpendingOutlinedIcon />,
  },
  {
    href: '/care',
    title: 'Care',
    icon: <StethoscopeIcon />,
  },
  {
    href: '/care/virtual',
    title: 'My Health',
    icon: <HeartbeatIcon />,
    active: true,
  },
];

export const mobileItems = [
  {
    href: '/',
    title: 'Home',
    icon: <AccoladeOutlinedIcon />,
  },
].concat(desktopItems);

const StyledNavigationMobile = styled.div`
  align-items: stretch;
  background: ${(props) => props.theme.colors.background};
  border-top: 1px solid ${(props) => props.theme.colors.gray3};
  bottom: 0;
  display: flex;
  justify-content: space-around;
  padding: ${(props) => props.theme.space.get('s')} 0;
  position: fixed;
  width: 100%;
  font-size: 14px;
  z-index: 1;
  // IM-28273: pseudo element below fixes iOS navbar bottom gap
  &:after {
    background: ${(props) => props.theme.colors.background};
    bottom: -3px;
    content: "";
    height: ${(props) => props.theme.space.get('3xs')};
    position: absolute;
    width: 100%;
  }
`;

const StyledNavigationDesktop = styled.div`
  align-items: stretch;
  /* background: ${(props) => props.theme.colors.background}; */
  bottom: 0;
  display: flex;
  padding: ${(props) => props.theme.space.get('s')} 0;
  position: sticky;
  border: 0;
  justify-content: space-between;
`;

const ROOT_PATH = '/';

export const Navigation = () => {
  const { color_scheme, application_title } = useBrand();

  const logo = React.useMemo(() => {
    if (color_scheme === 'celeste') {
      return <Image src={CelesteLogo} alt={application_title} width={100} height={40} />;
    }
    return <AccoladeLogo size="small" text="title" />;
  }, [color_scheme, application_title])

  return (
    <>
      <Desktop>
        <Container>
          <StyledNavigationDesktop>
            <NavigationItem>
              <NavigationLink
                aria-label="Home"
                tabIndex={1}
                href={ROOT_PATH}
                passHref
              >
                <a>
                  {logo}
                </a>
              </NavigationLink>
            </NavigationItem>
            {desktopItems.map((item, i) => (
              <NavigationItem className={`nav-item-${i}`} key={item.title}>
                <NavigationLink
                  tabIndex={1}
                  href={item.href}
                  passHref
                  data-ete={`nav-link-${item.title.toLowerCase()}`}
                  as={item.as}
                  active={item.active}
                >
                  <a>
                    <NavigationSubItem>
                      {item.icon}
                      {item.title}
                    </NavigationSubItem>
                  </a>
                </NavigationLink>
              </NavigationItem>
            ))}
            <div style={{ display: 'flex' }}>
              <NavigationItem>
                <NavigationLink
                  href="/notifications"
                  aria-label="Notifications"
                  passHref
                >
                  <a>
                    <NavigationSubItem>
                      <BellIcon />
                    </NavigationSubItem>
                  </a>
                </NavigationLink>
              </NavigationItem>
              <NavigationItem>
                <NavigationLink
                  aria-label="Settings"
                  href="/settings"
                  passHref
                >
                  <a>
                    <NavigationSubItem>
                      <SettingsIcon />
                    </NavigationSubItem>
                  </a>
                </NavigationLink>
              </NavigationItem>
            </div>
          </StyledNavigationDesktop>
        </Container>
      </Desktop>
      <TabletAndBelow>
        <StyledNavigationMobile>
          {mobileItems.map((item, i) => (
            <NavigationItem className={`nav-item-${i}`} key={item.title}>
              <NavigationLink
                href={item.href}
                passHref
                as={item.as}
                active={item.active}
              >
                <a>
                  <NavigationSubItem style={{ flexDirection: 'column' }}>
                    {item.icon}
                    {item.title}
                  </NavigationSubItem>
                </a>
              </NavigationLink>
            </NavigationItem>
          ))}
        </StyledNavigationMobile>
      </TabletAndBelow>
    </>
  );
};
