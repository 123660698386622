import React from 'react';
import backIcon from 'public/img/steps-icon-back.svg';
import backIconAcc from 'public/img/back-green.svg';
import { useAppVariant } from 'utils/hooks/useAppVariant';
import { useStepper } from '../StepperProvider';
import { BackIcon, StepperStepIndicatorBackStyled, StepperStepIndicatorStyled } from './StepperStepIndicator.styled';

export const StepperStepIndicator = ({
  total, current, onBackClick, isBackDisabled,
}) => {
  const { isAccoladeCare } = useAppVariant();
  return (
    <>
      {!isBackDisabled && (
        <StepperStepIndicatorBackStyled
          role="button"
          id="steps-back"
          onClick={onBackClick}
          tabIndex={0}
        >
          <BackIcon src={`${isAccoladeCare ? backIconAcc : backIcon}`} alt="back" />
        </StepperStepIndicatorBackStyled>
      )}
      <StepperStepIndicatorStyled isAccoladeCare={isAccoladeCare}>{`${current} of ${total}`}</StepperStepIndicatorStyled>
    </>
  );
};

export const StepperContextStepIndicator = ({ onBackClick, shouldDisableFirstStep = true }) => {
  const { selectors: { getTotal, getCurrent, isFirstStep }, actions: { toPrevStep } } = useStepper();
  return (
    <StepperStepIndicator
      total={getTotal()}
      current={getCurrent()}
      onBackClick={onBackClick ?? toPrevStep}
      isBackDisabled={shouldDisableFirstStep && isFirstStep()}
    />
  );
};
