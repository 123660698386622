import isFunction from 'lodash/isFunction';
import toPairs from 'lodash/toPairs';
import isArray from 'lodash/isArray';
import isObjectLike from 'lodash/isObjectLike';
import flow from 'lodash/fp/flow';
import map from 'lodash/fp/map';
import filter from 'lodash/fp/filter';
import uniq from 'lodash/fp/uniq';

/**
 * Builds a className based on args.
 * @param  {...any} args input parameters to build a className
 *
 * Usage:
 *  buildClassName('foo', 'bar') -> 'foo bar'
 *  buildClassName(['foo', 'bar']) -> 'foo bar'
 *  buildClassName('koo', ['foo', 'bar']) -> 'koo foo bar'
 *  buildClassName('foo', ['foo', 'bar']) -> 'foo bar'
 *  buildClassName({foo: true, bar: false}) -> 'foo'
 *  buildClassName({foo: () => true, bar: () => false}) -> 'foo'
 */
export const buildClassName = (...args) => {
  const processArg = (a) => {
    if (!a) {
      return '';
    }
    if (typeof a === 'string') {
      return a.trim().split(/\s+/).join(' ');
    }
    if (isArray(a)) {
      return buildClassName.apply(this, a);
    }
    if (isObjectLike(a)) {
      const isIn = v => Boolean(isFunction(v) ? v() : v);
      return buildClassName(toPairs(a).map(([key, value]) => (isIn(value) ? key : undefined)).filter(Boolean));
    }
    return buildClassName(`${a}`);
  };

  const res = flow(
    map(processArg),
    filter(Boolean),
    uniq,
  )(args);
  return res.join(' ');
};

// TODO: rewrite in Typescript so that ts-users of this helper does not throw errors due to incorrect type inference
/**
 * Construct a Bulma className for the specific size
 * @param {*} type null or 'offset'
 * @param {*} size 1, 2, ..., 12, '3/4', '2/3', '1/2', '1/3', '1/4', '4/5', '3/5', '2/5', '1/5', 'full'
 * @param {*} device Optional. One of 'mobile', 'tablet', 'desktop', 'widescreen', 'fullhd'.
 */
export const constructBulmaSizeClassName = (type, size, device) => {
  if (typeof size === 'undefined') {
    return undefined;
  }
  const sz = {
    '3/4': 'three-quarters',
    '2/3': 'two-thirds',
    '1/2': 'half',
    '1/3': 'one-third',
    '1/4': 'one-quarter',
    '4/5': 'four-fifths',
    '3/5': 'three-fifths',
    '2/5': 'two-fifths',
    '1/5': 'one-fifth',
  }[size] || size;
  const parts = ['is'];
  if (type) {
    parts.push(type);
  }
  parts.push(sz);
  if (device) {
    parts.push(device);
  }
  return parts.join('-');
};
