import styled from '@emotion/styled';
import { colors } from '../../../nextgen/plushcare-components';

export const StepperStepIndicatorStyled = styled.span`
  margin: 12px 0;
  font-size: 14px;
  font-weight: 600;
  display: inline-block;
  color: ${({ isAccoladeCare }) => (isAccoladeCare ? colors.ads.default : colors.adaRoyalBlue)};
`;

export const StepperStepIndicatorBackStyled = styled.a`
  opacity: ${({ disabled }) => (disabled ? '0.1' : 1)};
  outline: 0;
  border: none;
  outline-style: none;
  cursor: pointer;
`;

export const BackIcon = styled.img`
  margin: 0 10px 0 0;
`;
