/* eslint-disable react/jsx-props-no-spreading,import/no-unresolved,import/extensions */
import React, { ReactNode } from 'react';

import * as ComponentsHelpers from '../../../utils/ComponentsHelpers';
import { TDevice, TGapSize, TGapSizeByDevice } from './types';

const mkDeviceGap = (gapSize?: TGapSize, device?: TDevice) => (
  typeof gapSize !== 'undefined' ? `is-${gapSize}${device ? '-' : ''}${device || ''} is-variable` : undefined
);

export interface IProps {
  children?: ReactNode
  isDevice?: TDevice
  gapSize?: TGapSize
  gapSizeDevice?: TGapSizeByDevice
  isGapless?: boolean
  isMultiline?: boolean
  isVcentered?: boolean
  isCentered?: boolean
  className?: string
}

// builds Row which must include columns (<Column /> component)
const Columns = ({
  children,
  isDevice,
  gapSize,
  gapSizeDevice = {},
  isGapless = false,
  isMultiline = false,
  isVcentered = false,
  isCentered = false,
  className,
  ...props
}: IProps) => (
  <div
    className={
      ComponentsHelpers.buildClassName(
        'columns',
        className,
        {
          'is-gapless': isGapless,
          'is-multiline': isMultiline,
          'is-vcentered': isVcentered,
          'is-centered': isCentered,
        },
        isDevice ? `is-${isDevice}` : null,
        mkDeviceGap(gapSize),
        mkDeviceGap(gapSizeDevice.mobile, 'mobile'),
        mkDeviceGap(gapSizeDevice.tablet, 'tablet'),
        mkDeviceGap(gapSizeDevice.desktop, 'desktop'),
        mkDeviceGap(gapSizeDevice.widescreen, 'widescreen'),
        mkDeviceGap(gapSizeDevice.fullhd, 'fullhd'),
      )
    }
    {...props}
  >
    {children}
  </div>
);

export default Columns;
