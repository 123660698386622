import React, { useContext, useEffect } from 'react';

const FIRST_STEP = 1;
const StepperContext = React.createContext({ total: FIRST_STEP, current: FIRST_STEP });
const StepperDispatchContext = React.createContext();

const isFirstStep = step => step === FIRST_STEP;
const isLastStep = (step, total) => step === total;
const isStepInRange = (step, total) => step >= FIRST_STEP && step <= total;
const getNextStep = (step, total) => (!isLastStep(step, total) ? step + 1 : step);
const getPrevStep = step => (!isFirstStep(step) ? step - 1 : step);

const ACTION_NEXT = 'NEXT_STEP';
const ACTION_PREV = 'PREV_STEP';
const ACTION_SET_CURRENT = 'SET_CURRENT_STEP';
const ACTION_SET_TOTAL = 'SET_TOTAL_STEPS';

function stepperReducer(state, action) {
  switch (action.type) {
    case ACTION_NEXT: {
      return { ...state, current: getNextStep(state.current, state.total) };
    }
    case ACTION_PREV: {
      return { ...state, current: getPrevStep(state.current) };
    }
    case ACTION_SET_CURRENT: {
      return { ...state, current: isStepInRange(action.step, state.total) ? action.step : state.current };
    }
    case ACTION_SET_TOTAL: {
      return { ...state, total: action.total };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

const makeActions = dispatch => ({
  toNextStep: () => dispatch({ type: ACTION_NEXT }),
  toPrevStep: () => dispatch({ type: ACTION_PREV }),
  setCurrentStep: step => dispatch({ type: ACTION_SET_CURRENT, step }),
  goToStart: () => dispatch({ type: ACTION_SET_CURRENT, FIRST_STEP }),
  setTotalSteps: total => dispatch({ type: ACTION_SET_TOTAL, total }),
});

export const StepperProvider = ({
  total = FIRST_STEP, current = FIRST_STEP, children,
}) => {
  const [state, dispatch] = React.useReducer(stepperReducer, { total, current });
  // We need to have this useEffect because when the total or current props update, the state in the context doesn't
  // also update. To solve this, we listen for changes on the props, and trigger the dispatch to update the context
  // accordingly.
  //
  // The reason that we need to watch for current + total changing is because if we bucket the user into an AB test
  // that changes the number of steps in the flow that they're in, we need to reflect that in the stepper.
  useEffect(() => {
    dispatch({ type: ACTION_SET_TOTAL, total });
    dispatch({ type: ACTION_SET_CURRENT, step: current });
  }, [current, total]);

  return (
    <StepperContext.Provider value={state}>
      <StepperDispatchContext.Provider value={dispatch}>
        {children}
      </StepperDispatchContext.Provider>
    </StepperContext.Provider>
  );
};

// creates functions which enables selecting stepper state data without accessing it directly
const makeSelectors = ({ current, total }) => ({
  isFirstStep: () => isFirstStep(current),
  isLastStep: () => isLastStep(current, total),
  isStepInRange: () => isStepInRange(current, total),
  getNextStep: () => getNextStep(current, total),
  getPrevStep: () => getPrevStep(current, total),
  getTotal: () => total,
  getCurrent: () => current,
});

export const useStepper = () => {
  const state = useContext(StepperContext);
  const dispatch = useContext(StepperDispatchContext);
  return { actions: makeActions(dispatch), selectors: makeSelectors(state) };
};
