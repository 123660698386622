import React, {
  AnchorHTMLAttributes,
  ComponentPropsWithRef,
  ElementRef,
} from "react";
import styled, { css } from "styled-components";
import { TypographyProps, $TypographyProps } from "@accolade/design-system-react/styles/typography";
import { TypographyStyles } from "@accolade/design-system-react";

/**
 * LinkProps is the public interface.
 */
type AnchorElement = ElementRef<"a">;
type AnchorElementWithRef = ComponentPropsWithRef<"a">;
export interface LinkProps
  extends Partial<TypographyProps>,
  AnchorElementWithRef,
  AnchorHTMLAttributes<HTMLAnchorElement> {
  to: string;
  /**
   * An alternative color for the link
   */
  alt?: boolean;
}

/**
 * StyledTextProps is the internal interface
 * for the styled component itself.
 */
export interface StyledLinkProps
  extends $TypographyProps,
  AnchorHTMLAttributes<HTMLAnchorElement> {
  /**
   * An alternative color for the link
   */
  variation: "primary" | "tertiary";
}

const StyledLink = styled.a<StyledLinkProps>`
    ${TypographyStyles}
    text-decoration: none;
    cursor: pointer;
    /**
    * Custom line thickness and offset based on Figma
    */
    :hover,
    :active,
    :focus {
      text-decoration-line: underline;
      text-decoration-thickness: ${(props) =>
    css`calc(${props.theme.space.get("3xs")} / 4)`};
      text-underline-offset: ${(props) =>
    css`calc(${props.theme.space.get("3xs")} / 4)`};
    }

    :disabled {
      cursor: not-allowed;
    }

    color: ${(props) => props.theme.colors[props.variation].default};
    :hover {
      color: ${(props) => props.theme.colors[props.variation].hover};
    }
    :active {
      color: ${(props) => props.theme.colors[props.variation].selected};
    }
    :disabled {
      color: ${(props) => props.theme.colors[props.variation].disabled};
    }
    :focus {
      outline: none;
    }
  `;

export const Link = React.forwardRef<AnchorElement, LinkProps>(
  (
    {
      to,
      fontFamily = "default",
      alt,
      fontSize,
      fontWeight = "normal",
      lineHeight = "medium",
      size = "m",
      ...rest
    }: LinkProps,
    ref
  ) => {
    const variation = alt ? "tertiary" : "primary";

    return (
      <StyledLink
        href={to}
        $fontFamily={fontFamily}
        variation={variation}
        $fontSize={fontSize}
        $fontWeight={fontWeight}
        $lineHeight={lineHeight}
        size={size}
        ref={ref}
        {...rest}
      />
    );
  }
);
