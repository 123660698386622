import React from "react";
import { LinkProps as NextLinkProps } from "next/link";
import { styled } from "styled-components";
import { Link as AdsLink } from "./Link";

interface LinkProps extends NextLinkProps {
  active?: boolean;
  children?: React.ReactNode;
  tabIndex?: number;
}

export const NAV_CLASS = "nav-link";
export const NAV_ACTIVE_CLASS = "nav-link-active";

export const NavigationLink = ({
  active = false,
  href,
  tabIndex = 0,
  ...rest
}: LinkProps) => {
  // It's recommended to not set tabIndex of a interactive elements like
  // anchor tags and buttons if user defined value is zero.
  const tabIndexCheck = tabIndex !== 0 ? { tabIndex } : {};
  return (
    <CustomAdsLink
      {...tabIndexCheck}
      to={href as string}
      className={active ? `${NAV_CLASS} ${NAV_ACTIVE_CLASS}` : NAV_CLASS}
      style={{ alignItems: "stretch", display: "flex" }}
      {...rest}
    />
  );
};

const CustomAdsLink = styled(AdsLink)`
  &.nav-link-active {
    color: rgb(9, 113, 103);

    > a > div {
      color: inherit;
    }
  }
`;
