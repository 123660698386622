import React from "react";
import { styled } from "styled-components";

interface NavigationItemProps {
    children: React.ReactNode;
}

export const NavigationItem = styled.span<NavigationItemProps>`
  align-items: stretch;
  display: flex;
  margin: 0 ${(props) => props.theme.space.get("3xs")};
  align-items: center;

  .nav-link:not(.nav-link-active) {
    color: ${(props) => props.theme.colors.secondary.default};
  }
`;

