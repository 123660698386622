/* eslint-disable react/jsx-props-no-spreading,import/no-unresolved,import/extensions */
import React, { ReactNode } from 'react';

import * as ComponentsHelpers from '../../../utils/ComponentsHelpers';
import { TSizeByDevice, TSizes } from './types';

export interface IProps {
  children?: ReactNode
  isNarrow?: boolean
  isSize?: TSizes
  sizeByDevice?: TSizeByDevice
  isOffset?: Exclude<TSizes, 'full'>
  offsetByDevice?: TSizeByDevice
  className?: string
  style?: any
}

const Column = ({
  children,
  isNarrow = false,
  isSize,
  sizeByDevice = {},
  isOffset,
  offsetByDevice = {},
  className,
  ...props
}: IProps) => (
  <div
    className={ComponentsHelpers.buildClassName(
      'column',
      className,
      isNarrow ? 'is-narrow' : '',
      // @ts-ignore
      ComponentsHelpers.constructBulmaSizeClassName(null, isSize),
      ComponentsHelpers.constructBulmaSizeClassName(null, sizeByDevice.mobile, 'mobile'),
      ComponentsHelpers.constructBulmaSizeClassName(null, sizeByDevice.tablet, 'tablet'),
      ComponentsHelpers.constructBulmaSizeClassName(null, sizeByDevice.desktop, 'desktop'),
      ComponentsHelpers.constructBulmaSizeClassName(null, sizeByDevice.widescreen, 'widescreen'),
      ComponentsHelpers.constructBulmaSizeClassName(null, sizeByDevice.fullhd, 'fullhd'),
      // @ts-ignore
      ComponentsHelpers.constructBulmaSizeClassName('offset', isOffset),
      ComponentsHelpers.constructBulmaSizeClassName('offset', offsetByDevice.mobile, 'mobile'),
      ComponentsHelpers.constructBulmaSizeClassName('offset', offsetByDevice.tablet, 'tablet'),
      ComponentsHelpers.constructBulmaSizeClassName('offset', offsetByDevice.desktop, 'desktop'),
      ComponentsHelpers.constructBulmaSizeClassName('offset', offsetByDevice.widescreen, 'widescreen'),
      ComponentsHelpers.constructBulmaSizeClassName('offset', offsetByDevice.fullhd, 'fullhd'),
    )}
    {...props}
  >
    {children}
  </div>
);

export default Column;
